import React, { useEffect } from "react"
import { Link } from "gatsby"

import "./homepage.scss"

import Carousel from "../../widgets/carousel/carousel"

import fbIcon from "../../images/home-banner/facebook.svg"
import linkedinIcon from "../../images/home-banner/linkedin.svg"
import indiamartIcon from "../../images/home-banner/indimart.png"
import instagramIcon from "../../images/home-banner/instagram.svg"
import mediumIcon from "../../images/home-banner/medium.svg"
import twitteIcon from "../../images/home-banner/twitter.svg"

import integratedSolarStreetLight from "../../images/home-range-of-products/Integrated-Solar-Street-Light.jpg"
import solarOutdoorLight from "../../images/home-range-of-products/Solar-Outdoor-Light.jpg"
import energySavingSolarHomeLightingSystem from "../../images/home-range-of-products/Energy-Saving-Solar-Home-Lighting-System.jpg"
import solarHomeLightingSystem from "../../images/home-range-of-products/Solar-Home-Lighting-System.jpg"
import liIonBatteryPack1 from "../../images/home-range-of-products/Li-Ion-Battery-Pack-1.jpg"
import liIonBatteryPack2 from "../../images/home-range-of-products/Li-Ion-Battery-Pack-2.jpg"
import liIonBatteryPack3 from "../../images/home-range-of-products/Li-Ion-Battery-Pack-3.jpg"
import hslLedStreetLight from "../../images/home-range-of-products/HSL-LED-Street-Light.jpg"
import outdoorStreetLight from "../../images/home-range-of-products/Outdoor-Street-Light.jpg"

import remLogo from "../../images/good-company/rissala-electric-motors.png"
import sealOfUP from "../../images/good-company/seal-of-up.png"
import sggsLogo from "../../images/good-company/sggs.png"
import havellsLogo from "../../images/good-company/havells.png"
import autopalMPGLogo from "../../images/good-company/autopal-mpg.png"
import parkIndLogo from "../../images/good-company/park-industries.png"
import oranaLogo from "../../images/good-company/ORANA.png"

import autopalLogo from "../../images/partners/autopal.png"
import dhampurGreenLogo from "../../images/partners/dhampur-green.png"
import rem2Logo from "../../images/partners/rem.png"
import evoletLogo from "../../images/partners/evolet.png"
import rissalaOrganicsLogo from "../../images/partners/rissalaOrganics.png"
import rissalaPoloLogo from "../../images/partners/rissalaPolo.png"
import useDarkMode from "use-dark-mode"

// import Carousel from "../../widgets/carousel/carousel"

const HomePage = props => {
  const darkMode = useDarkMode(false)

  useEffect(() => {
    if (darkMode.value === true) {
      darkMode.disable()
    }
  }, [])

  const uselessText =
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."

  return (
    <div className={darkMode.value ? "dark-mode" : "light-mode"}>
      <div
        className="home-banner"
        style={darkMode.value ? { filter: "grayscale(100%)" } : {}}
      >
        <div className="home-banner-content home-banner-banner"></div>
        <div className="home-banner-content home-banner-learn-more"></div>
        <div className="home-banner-content home-banner-learn-buttons">
          <Link
            to="https://www.facebook.com/highstreetlighting/"
            target="_blank"
            className="home-banner-button"
            style={{ marginLeft: "0" }}
          >
            <img src={fbIcon} alt="facebook" />
          </Link>
          <Link
            to="https://www.linkedin.com/company/high-street-lighting-private-limited/about/"
            target="_blank"
            className="home-banner-button"
          >
            <img src={linkedinIcon} alt="linkedin" />
          </Link>
          <Link
            to="https://www.indiamart.com/highstreetlighting/"
            target="_blank"
            className="home-banner-button"
          >
            <img src={indiamartIcon} alt="indiamart" />
          </Link>
          <Link
            to="https://instagram.com/high_street_lighting?igshid=i0p8rl1rf5gr"
            target="_blank"
            className="home-banner-button"
          >
            <img src={instagramIcon} alt="instagram" />
          </Link>
          <Link
            to="https://medium.com/@highstreetlightings"
            target="_blank"
            className="home-banner-button"
          >
            <img src={mediumIcon} alt="medium" />
          </Link>
          <Link
            to="https://twitter.com/HighStreetLigh1?s=09"
            target="_blank"
            className="home-banner-button"
          >
            <img src={twitteIcon} alt="twitter" />
          </Link>
        </div>
      </div>
      <div className="home-content">
        {/* Why HSL */}
        <div className="home-why-hsl">Why choose HSL?</div>
        <div className="home-why-hsl-content">
          <p>
            <b>High Street Lighting</b> was found in 2015 with a vision to
            manufacture the best products of the solar industry. We manufacture
            solar street lights, solar home lighting system, LED streetlights,
            lithium-ion rechargeable battery packs among various other products.
          </p>
          <p>
            We have got you covered for all your solar needs now. The core
            strength of the company is great quality products at an affordable
            price along with optimal design to effectively manage your energy
            needs.
          </p>
          <p>
            We want you to experience the solar shift and live in solitude and
            with no fear of your electric bills.
          </p>
          <p>
            It is our ardor to provide you with superb customer loyalty and
            administration in each and every element of your solar board setup.
          </p>
        </div>

        {/* Feature Boxes */}
        <div className="home-hsl-features">
          <div
            className="home-hsl-feature-box"
            style={{ backgroundColor: "#EF7F1A" }}
          >
            <div className="home-hsl-feature-box-img award-img"></div>
            <div className="home-hsl-feature-box-content">
              <span>
                <b>Quality Control</b>
              </span>
              <p>
                We have high-quality Solar products and lithium-ion battery
                packs and have a strict quality control team for more efficient
                products
              </p>
            </div>
          </div>
          <div
            className="home-hsl-feature-box"
            style={{ backgroundColor: "#8ABB31" }}
          >
            <div className="home-hsl-feature-box-img support-img"></div>
            <div className="home-hsl-feature-box-content">
              <span>
                <b>24*7 Support</b>
              </span>
              <p>High technical and after-sales service available 24*7</p>
            </div>
          </div>
          <div
            className="home-hsl-feature-box"
            style={{ backgroundColor: "white" }}
          >
            <div className="home-hsl-feature-box-img report-img"></div>
            <div className="home-hsl-feature-box-content">
              <span>
                <b>R{"&"}D</b>
              </span>
              <p>
                Efficient Research and development team on board for
                manufacturing high-quality solar products and lithium-ion
                battery packs
              </p>
            </div>
          </div>
        </div>

        {/* Our Range of Products */}
        <div className="home-range-heading">
          <div className="home-range-heading-dots"></div>
          <div
            className="home-range-heading-heading"
            style={darkMode.value ? { color: "white" } : {}}
          >
            <b>Our Range of Products</b>
          </div>
          <div className="home-range-heading-dots"></div>
        </div>
        <ProductRangeWidget
          left
          imgs={[
            {
              src: integratedSolarStreetLight,
              alt: "Integrated Solar Street Light",
              title: "Integrated Solar Street Light",
            },
            {
              src: solarOutdoorLight,
              alt: "Solar Outdoor Light",
              title: "Solar Outdoor Light",
            },
          ]}
          heading="All in one solar street light"
          content={
            <>
              <p>
                All in one streetlight are new and extremely versatile solar
                powered led street lights. HSL Integrated Solar Street Light is
                made in India with High Efficiency Mono Crystalline Solar PV
                Module, Lithium Ferro Phosphate battery with Inbuilt BMS
                technology, High Efficiency LED with MPPT Solar Charge
                Controller & Aluminum Extruded body. Integrated SSL is specially
                designed with Panasonic PIR Motion Sensor to control the Light
                ON/Off during any activity near the light which increases the
                backup time and life of LiFePO4 battery.
              </p>
              <Link
                to="/products/all-in-one-solar-street-light"
                className="know-more"
              >
                Know More
              </Link>
            </>
          }
        />
        <ProductRangeWidget
          right
          imgs={[
            {
              src: solarHomeLightingSystem,
              alt: "Solar Home Lighting System",
              title: "Solar Home Lighting System",
            },
            {
              src: energySavingSolarHomeLightingSystem,
              alt: "Energy Saving Solar Home Lighting System",
              title: "Energy Saving Solar Home Lighting System",
            },
          ]}
          heading="Home Lighting System"
          content={
            <>
              <p>
                HSL provides an ideal Solar Home Lighting System which is not
                only affordable but also efficient and effective. The solar
                cells integrated into the device turns the energy of the sun
                into electricity, helping you reduce your electricity bill and
                gain round the clock electricity. HSL home lighting system is
                compact, light-weight, and easy to install. It is Used in
                residential functions. The system ‘s additives include solar
                cells, solar battery, charging controller, lights, fan and a
                television.
              </p>
              <Link
                to="/products/solar-home-lighting-system"
                className="know-more"
              >
                Know More
              </Link>
            </>
          }
        />
        <ProductRangeWidget
          left
          imgs={[
            {
              src: liIonBatteryPack1,
              alt: "Lithium Ion Battery Pack",
              title: "Lithium Ion Battery Pack",
            },
            {
              src: liIonBatteryPack2,
              alt: "HSL Battery Pack",
              title: "HSL Battery Pack",
            },
            {
              src: liIonBatteryPack3,
              alt: "High Street Lighting Battery Pack",
              title: "High Street Lighting Battery Pack",
            },
          ]}
          heading="Li-ion Battery Packs"
          content={
            <>
              <p>
                Lithium-ion batteries provide more energy in a smaller package
                without carbon emission. Keep your home lighten up. We at HSL
                are active in manufacturing and exporting an exclusive range of
                Battery packs.These are excessive energy-density rechargeable
                batteries which can be widely used in patron devices. Lithium
                Ion Batteries are slightly lighter than other types of
                similar-sized rechargeable batteries. LIBs are used drastically
                in transportable devices. These batteries are typically utilized
                in PDAs, iPod's, cell phones and laptops.
              </p>
              <Link
                to="/products/lithium-ion-cell-and-battery-pack"
                className="know-more"
              >
                Know More
              </Link>
            </>
          }
        />
        <ProductRangeWidget
          right
          imgs={[
            {
              src: hslLedStreetLight,
              alt: "HSL LED Street Light",
              title: "HSL LED Street Light",
            },
            {
              src: outdoorStreetLight,
              alt: "Outdoor Street Light",
              title: "Outdoor Street Light",
            },
          ]}
          heading="LED Street Light"
          content={
            <>
              <p>
                High Street LED light is designed for the street lighting demand
                and fully meets the special requirements of street lighting.
                They are ideal for illumination in the main roads/areas of
                campuses, lighting up streets and gardens. It gives a wider
                dispersion of light covering the maximum area. This product
                adopts the high power LED as the light source, using dozens of
                high power Nichia emitters of 5 watt LED. With the words leading
                optical allocation, advanced thermal, structural, and circuit
                design, it is highly cost-effective.
              </p>

              <Link to="/products/led-street-light" className="know-more">
                Know More
              </Link>
            </>
          }
        />

        {/* You are in good company */}
        <div className="home-good-company-heading">
          <div className="home-good-company-heading-container">
            <div className="home-good-company-heading-text">
              You are in good company
            </div>
            <div className="home-good-company-heading-lead-lines">
              <div className="home-good-company-heading-lead-line-top"></div>
              <div className="home-good-company-heading-lead-line-bottom"></div>
            </div>
          </div>
        </div>
        <div className="home-good-company-content">
          <div className="home-good-company-content-row">
            <a href="https://evoletindia.com" target="_blank">
              <img
                src={remLogo}
                alt="Rissala Electric Motors"
                title="Rissala Electric Motors"
              />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="http://up.gov.in" target="_blank">
              <img
                src={sealOfUP}
                alt="UP Government"
                title="UP Government"
                style={{ maxWidth: "10rem", maxHeight: "10rem" }}
              />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://www.havells.com" target="_blank">
              <img
                src={havellsLogo}
                alt="Havells India"
                title="Havells India"
              />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://www.parkindustries.com" target="_blank">
              <img
                src={parkIndLogo}
                alt="Park Industries"
                title="Park Industries"
              />
            </a>
          </div>
          <div className="home-good-company-content-row">
            <a href="https://www.autopal.com" target="_blank">
              <img
                src={autopalMPGLogo}
                alt="Autopal Jaipur"
                title="Autopal Jaipur"
              />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a>
              <img src={sggsLogo} alt="" title="" />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://oranagroup.in/orana-conventions/" target="_blank">
              <img
                src={oranaLogo}
                alt="Orana conventions"
                title="Orana conventions"
              />
            </a>
          </div>
        </div>

        {/* Our Partners */}
        <div className="home-partners-heading">
          <div className="home-partners-heading-container">
            <div className="home-partners-heading-lead-lines">
              <div className="home-partners-heading-lead-line-top"></div>
              <div className="home-partners-heading-lead-line-bottom"></div>
            </div>
            <div className="home-partners-heading-text">Our Partners</div>
          </div>
        </div>
        <div className="home-good-company-content">
          <div className="home-good-company-content-row">
            <a href="https://www.autopal.com" target="_blank">
              <img src={autopalLogo} alt="" title="Autopal" />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://www.dhampurgreen.com" target="_blank">
              <img src={dhampurGreenLogo} alt="" title="Dhampur Green" />
            </a>

            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://www.evoletindia.com" target="_blank">
              <img
                src={evoletLogo}
                alt=""
                title="Evolet India"
                style={{ maxWidth: "150px", marginTop: "1rem" }}
              />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://www.evoletindia.com" target="_blank">
              <img
                src={rem2Logo}
                alt=""
                title="Rissala Electric Motors"
                style={{ maxWidth: "150px", marginTop: "3rem" }}
              />
            </a>
          </div>
          <div className="home-good-company-content-row">
            <a href="https://www.rissalaorganics.com" target="_blank">
              <img
                src={rissalaOrganicsLogo}
                alt=""
                title="Rissala Organics"
                style={{ maxWidth: "150px", marginTop: "1.5rem" }}
              />
            </a>
            <div
              className="divider"
              style={darkMode.value ? { backgroundColor: "white" } : {}}
            />
            <a href="https://www.rissalapoloclub.com" target="_blank">
              <img src={rissalaPoloLogo} alt="" title="Rissala Polo Club" />
            </a>
          </div>
        </div>

        {/* Wall of Fame */}
        <div className="home-wall-heading">
          <div className="home-wall-heading-container">
            <div className="home-wall-heading-lead-lines">
              <div className="home-wall-heading-lead-line-top"></div>
              <div
                className="home-wall-heading-lead-line-bottom"
                style={darkMode.value ? { backgroundColor: "white" } : {}}
              ></div>
            </div>
            <div
              className="home-wall-heading-text"
              style={darkMode.value ? { color: "white" } : {}}
            >
              Our Wall of Fame
            </div>
          </div>
        </div>
        <Testimonials
          data={[
            {
              author: "ROHIT GARG( MD) (GRM OVERSEAS)",
              content:
                "HSL led streetlight gives wider dispersion of light covering  maximum area. Initially, there were 10 street lights of 250-watt which have been replaced by 6 street lights of 90watt by High Street Lighting.",
            },
            {
              author: "ROBIN KAPOOR (MD) (PARK NONWOVEN)",
              content:
                "HSL led street light is of good quality, aesthetically designed and gives sufficient light. It has helped to save a lot of energy bills and increase our productivity tremendously.",
            },
            {
              author: "HARDEEP SINGH (FOUNDING DIRECTOR) ",
              content:
                "The battery packs provided by HSL were of very good quality. They have international quality products and have provided us with the best service.",
            },
          ]}
        />
        <div className="footer-asset"></div>
      </div>
    </div>
  )
}

export default HomePage

const ProductRangeWidget = props => {
  var contentDiv = (
    <div className="home-range-product-content">
      <div className="home-range-product-content-heading">
        <b>{props.heading}</b>
      </div>
      <div className="home-range-product-content-content">{props.content}</div>
    </div>
  )

  if (props.right) {
    return (
      <div className="home-range-product-container">
        {contentDiv}
        <div className="home-range-product-img home-range-product-img-right">
          <Carousel>
            {props.imgs.map(img => (
              <img src={img.src} alt={img.alt} title={img.title} />
            ))}
          </Carousel>
        </div>
      </div>
    )
  } else {
    return (
      <div className="home-range-product-container">
        <div className="home-range-product-img home-range-product-img-left">
          <Carousel>
            {props.imgs.map(img => (
              <img src={img.src} alt={img.alt} title={img.title} />
            ))}
          </Carousel>
        </div>
        {contentDiv}
      </div>
    )
  }
}

class Testimonials extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      posts: this.props.data,
      currentpost: this.props.data[0],
      currentIndex: 0,
    }

    this.interval = setInterval(() => this.nextPost(), 4000)
  }

  nextPost() {
    if (this.state.currentIndex === this.state.posts.length - 1) {
      this.setState({
        currentpost: this.state.posts[0],
        currentIndex: 0,
      })
    } else {
      this.setState({
        currentpost: this.state.posts[this.state.currentIndex + 1],
        currentIndex: this.state.currentIndex + 1,
      })
    }
  }

  render() {
    return (
      <>
        <div className="home-wall-content">
          <div className="home-wall-content-comment">
            <div className="home-wall-content-comment-sender">
              <b>{this.state.currentpost.author}</b>
            </div>
            <div className="home-wall-content-comment-comment">
              {this.state.currentpost.content}
            </div>
          </div>
        </div>
      </>
    )
  }
}
