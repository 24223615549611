import React from "react"
import "./carousel.scss"

export default class Carousel extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      images: this.props.children.map(img => img),
      styles: this.props.children.map((image, i) => {
        var opacity = "0"
        if (i == 0) {
          opacity = "1"
        }
        return {
          position: "relative",
          top: "0",
          left: `${-100 * i}%`,
          opacity: opacity,
        }
      }),
      activeIndex: 0,
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => this.next(), 3000)
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  resetView = () => {
    this.setState({
      styles: this.state.images.map((image, i) => {
        var opacity = "0"
        return {
          position: "relative",
          top: "0",
          left: `${-100 * i}%`,
          opacity: opacity,
        }
      }),
    })
  }

  setView = () => {
    this.setState({
      styles: this.state.images.map((image, i) => {
        var opacity = "0"
        if (i === this.state.activeIndex) {
          opacity = "1"
        }
        return {
          position: "relative",
          top: "0",
          left: `${-100 * i}%`,
          opacity: opacity,
        }
      }),
    })
  }

  prev = () => {
    this.resetView()
    if (this.state.activeIndex === 0) {
      this.setState(
        {
          activeIndex: this.state.images.length - 1,
        },
        () => {
          this.setView()
        }
      )
    } else {
      this.setState(
        {
          activeIndex: this.state.activeIndex - 1,
        },
        () => {
          this.setView()
        }
      )
    }
  }

  next = () => {
    this.resetView()
    if (this.state.activeIndex === this.state.images.length - 1) {
      this.setState(
        {
          activeIndex: 0,
        },
        () => {
          this.setView()
        }
      )
    } else {
      this.setState(
        {
          activeIndex: this.state.activeIndex + 1,
        },
        () => {
          this.setView()
        }
      )
    }
  }

  navigateSlide = slideNum => {
    clearInterval(this.interval)
    this.resetView()
    this.setState(
      {
        activeIndex: slideNum,
      },
      () => {
        this.setView()
        this.interval = setInterval(() => this.next(), 3000)
      }
    )
  }

  render() {
    return (
      <>
        <div className="slider">
          <div className="slider-controls">
            <button className="slider-control-left" onClick={this.prev}>
              <div className="slider-chevron-left"></div>
            </button>
            <div className="slider-control-buffer"></div>
            <button className="slider-control-right" onClick={this.next}>
              <div className="slider-chevron-right">

              </div>
            </button>
          </div>
          <div className="slider-bottom-hint">
            {this.state.styles.map((style, i) => {
              var bgColor = "whitesmoke"
              if (style.opacity == "1") {
                bgColor = "grey"
              }
              return (
                <button
                  key={i}
                  className="slider-bottom-hint-marker"
                  style={{ backgroundColor: bgColor }}
                  onClick={() => this.navigateSlide(i)}
                ></button>
              )
            })}
          </div>
          <div className="slides">
            {this.state.images.map((image, i) => {
              return (
                <div key={i} className="slide" style={this.state.styles[i]}>
                  {image}
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }
}
