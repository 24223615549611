import React from "react"
import SEO from "../components/layout/seo"

import HomePage from "../components/index/homepage"
import Layout from "../components/layout/layout"

const IndexPage = () => (
  <Layout home={true}>
    <SEO title="Home" />
    <HomePage />
  </Layout>
)

export default IndexPage
